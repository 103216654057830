
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import AdminQuoteRequest from 'tradingmate-modules/src/models/api/admin/quotes/AdminQuoteRequest'
import { BusinessBranchContactMethod } from 'tradingmate-modules/src/models/api/businesses'
import ContactMethodType from 'tradingmate-modules/src/models/api/ContactMethodType'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'
import { Config } from 'tradingmate-modules/src/environment'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})

export default class QuoteRequestDetail extends Vue {
  private config = Config
  private loading = false
  private quoteRequest: AdminQuoteRequest | null = null
  private isoToDisplayDate = Utils.isoToDisplayDate

  private isoToQuoteDate (iso: string): string {
    return (new Date(iso)).toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
  }

  mounted (): void {
    this.getQuoteRequest()
  }

  getContactMethod (contacts: BusinessBranchContactMethod[], type: ContactMethodType): string {
    return contacts.find((element) => element.ContactMethodType === type)?.Value ?? 'N/A'
  }

  getQuoteRequest (): void {
    const quoteRequestId = this.$route.params.id
    this.loading = true
    Services.API.Admin.QuoteRequest.GetAdminQuoteRequest(quoteRequestId)
      .then((returnData) => { this.quoteRequest = returnData })
      .finally(() => { this.loading = false })
  }

  handleClicked (link: string) {
    window.open(link, '_blank')
  }
}

